/* Styles for the ResponsableCard component */
.cardResponsable {
    display: flex;
    align-items: center;
    min-width: 30%;
    margin: 20px auto;
    border-radius: 15px; /* Modified border-radius */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    overflow: hidden;
}

.cardResponsable:hover {
    transform: translateY(-5px);
}

.cardResponsable .MuiCardMedia-root {
    flex: 0 0 140px;
    height: 140px;
    object-fit: cover;
    border-radius: 10px 0 0 10px;
}

.cardResponsable .MuiCardContent-root {
    flex: 1;
    padding: 20px;
    background-color: #f9f9f9;
    text-align: left;
}

.cardResponsable .MuiTypography-h5 {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 10px;
}

.cardResponsable .MuiTypography-colorTextSecondary {
    font-size: 1.1em;
    color: #666;
    margin-bottom: 10px;
}

.cardResponsable .MuiTypography-body2 {
    font-size: 1em;
    color: #444;
    font-weight: bold;
}
