.destacadoTienda {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d8041d9d;
    width: 100%;
    border-radius: 50px;
    padding: 10px; /* Espaciado interno para mejorar la apariencia en tamaños pequeños */
}

.infoDestacado {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%; /* Ocupa todo el ancho en pantallas pequeñas */
    max-width: 20%; /* Limita el ancho en pantallas grandes */
    margin-bottom: 20px;
    text-align: center;
    color: #fff;
}

.infoDestacado h1 {
    font-weight: bolder;
    font-size: 2.5rem;
    margin-bottom: 10px;
}

.infoDestacado h2 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 20px;
}

.infoDestacado svg {
    font-size: 3rem;
    margin-top: 10px;
}

.destacadoTienda .cardArticulo {
    margin: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.destacadoTienda .cardArticulo:hover {
    transform: scale(1.05);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
}

/* Media Queries para diseño responsive */
@media screen and (max-width: 1024px) {
    .infoDestacado {
        max-width: 100%; /* Ajusta el ancho en pantallas medianas */
    }

    .destacadoTienda {
        flex-direction: column; /* Disposición en columna en pantallas pequeñas */
        align-items: center;
        gap: 30px; /* Espaciado entre la infoDestacado y los artículos */
    }
}

@media screen and (max-width: 768px) {
   
    
    .infoDestacado {
        width: 100%;
    }

    .infoDestacado h1 {
        font-size: 2rem;
    }

    .infoDestacado h2 {
        font-size: 1.2rem;
    }

    .infoDestacado svg {
        font-size: 2.5rem;
    }
}
