.contacto {
    min-width: 80%;
    margin: 0 auto;
}

.responsable {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 20px;
    flex-wrap: wrap;
}

/* Media queries para pantallas más pequeñas */
@media (max-width: 768px) {
    .contacto {
        min-width: 90%;
    }

    .responsable {
        flex-direction: column;
        gap: 10px;
    }
}

@media (max-width: 480px) {
    .contacto {
        width: 100%;
    }

    .responsable {
        flex-direction: column;
        gap: 5px;
    }
}