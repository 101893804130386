.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  top: 0;
  padding: 10px 20px;
  box-sizing: border-box;
}

a {
  margin-left: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #000;
  margin: 0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

img {
  width: 100px;
  height: auto;
}

nav {
  margin-left: auto;
  margin-right: 10%;
}

.nav-list {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.language-flag-container {
  display: flex;
  align-items: center;
}

.language-flag {
  width: 40px;
  height: 30px;
  margin: 0 5px;
  cursor: pointer;
}

.language-flag:hover {
  opacity: 0.8;
}

.nav-active .nav-link {
  background-color: #000;
  color: #fff;
}

.idiomaButton {
  text-decoration: none;
  color: #333;
  font-weight: 600;
  padding: 8px 12px;
  margin-right: 8px;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
  white-space: nowrap;
  cursor: pointer;
}

.idiomaButton:hover {
  background-color: #000000;
  color: #fff;
}

/* Media Queries for Responsive Design */
@media (max-width: 980px) {
  .header {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .title {
    font-size: 2rem;
  }

  nav {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .nav-list {
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
  }

  a {
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 1.5rem;
  }

  img {
    width: 80px;
  }

  .nav-list {
    gap: 5px;
  }

  .language-flag {
    width: 30px;
    height: 20px;
  }
}