.patrocinador-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
    padding: 5px;
    border-radius: 8px;
    width: 200px;
  }
  
  .patrocinador-container:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-color: #f9f9f993;
  }
  
  .patrocinador-imagen {
    width: 100px;
    height: auto;
    object-fit: cover;
    border-radius: 5%;
    margin-bottom: 10px;
  }
  
  .patrocinador-nombre {
    font-size: 1.2rem;
    color: #333;
    text-align: center;
  }
  