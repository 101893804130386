.patrocinadoresContainer{
  width: 100%;
  margin-top: 30px;
}

.patrocinadoresCarousel {
  margin: 0 auto;
  padding: 20px 0;
  overflow: hidden;
  display: flex;

  
}

.patrocinadoresGroup {
  display: flex;
  gap: 20px;
  padding-right: 20px;
  will-change: transform;
  animation: scrolling 50s linear infinite;
}

.patrocinadoresCarousel:hover .patrocinadoresGroup {
  animation-play-state: paused;
}

@keyframes scrolling {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}