.nav-link {
  text-decoration: none;
  color: #000;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s;
  white-space: nowrap;
}
  
.nav-link:hover {
  background-color: #000;
  color: #fff;
}
