.card {
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25vw;
  margin: 0 auto;
  padding: 20px;
}

/* for all social containers*/
.socialContainer {
  min-width: 52px;
  min-height: 52px;
  background-color: rgb(53, 51, 51);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition-duration: .3s;
  margin: 20px;
}

/* instagram*/
.containerOne:hover {
  background-color: #d62976;
  transition-duration: .3s;
}

/* twitter*/
.containerTwo:hover {
  background-color: #00acee;
  transition-duration: .3s;
}

/* linkdin*/
.containerThree:hover {
  background-color: #0072b1;
  transition-duration: .3s;
}

/* Whatsapp*/
.containerFour:hover {
  background-color: #128C7E;
  transition-duration: .3s;
}

.socialContainer:active {
  transform: scale(0.9);
  transition-duration: .3s;
}

.socialSvg {
  width: 17px;
}

.socialSvg path {
  fill: rgb(255, 255, 255);
}

.socialContainer:hover .socialSvg {
  animation: slide-in-top 0.3s both;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
