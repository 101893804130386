.tienda{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80%;
}
.exclamation-icon {
    margin-bottom: 0.5%; 
    font-size: 3rem;
    vertical-align: middle;
    margin-right: 0.5rem;
}


h1{
    text-align: center;
}