/* From Uiverse.io by alexruix */
.cardArticulo {
    width: 100%; /* Ajuste al 100% del contenedor en pantallas pequeñas */
    max-width: 280px; /* Limitar el ancho máximo en pantallas grandes */
    min-height: 300px; /* Limitar la altura máxima */
    background-color: #fff;
    border-radius: 20px;
    margin: 1rem auto;
    padding: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.cardArticulo:hover {
    transform: scale(1.05);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
}

.cardArticulo img {
    width: 100%;
    height: auto;
    border-radius: 15px;
    object-fit: cover;
    margin-bottom: 15px;
}

.cardArticulo .infoPrecio {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

.cardArticulo .infoPrecio h2 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
}

.cardArticulo p {
    width: 100%;
    border-top: 1px solid #ddd;
    font-size: 0.9rem;
    color: #666;
    padding-top: 10px;
    margin-bottom: 15px;
}

.cardArticulo .tallas {
    width: 100%;
    text-align: left;
}

.cardArticulo .tallas h3 {
    font-size: 1rem;
    font-weight: bold;
    color: #444;
    margin-bottom: 8px;
}

.cardArticulo .tallas ul {
    display: flex;
    flex-wrap: wrap; /* Permite que las tallas se adapten */
    gap: 8px; /* Espacio entre los elementos */
    padding: 0;
    list-style: none;
    margin: 0;
}

.cardArticulo .tallas li {
    padding: 6px 12px;
    background-color: #f0f0f0;
    border-radius: 5px;
    font-size: 0.85rem;
    color: #333;
    text-align: center;
}

/* Media Queries */
@media (min-width: 768px) {
    .cardArticulo {
        width: 45%; /* Dos columnas en tabletas */
    }
}

@media (min-width: 1024px) {
    .cardArticulo {
        width: 22%; /* Cuatro columnas en escritorio */
    }
}
