.juegaConNosotros {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f596;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.trainingTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.trainingRow {
    border-bottom: 1px solid #ddd;
}

.trainingCategory {
    padding: 10px;
    font-weight: bold;
}

.trainingDetails {
    padding: 10px;
}