.historia-beltzak-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f596;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.historia-title {
  text-align: center;
  font-size: 4.0rem;
  color: #1d1d1d;
  font-weight: bold;
  margin-bottom: 30px;
  letter-spacing: 1px;
}

.historia-content {

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.historia-text {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #333;
}

.historia-paragraph {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 15px;
  font-family: 'Montserrat', sans-serif;
}

.paragraph-image {
  min-width: 450px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.paragraph-image:hover {
  transform: scale(1.05);
}

.historia-beltzak-container::before {
  content: "";
  background-image: url("../../assets//logoBeltzak.webp");
  background-size: cover;
  opacity: 0.05;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

@media (max-width: 1200px) {
  .historia-title {
    font-size: 2.5rem;
  }

  .historia-text {
    font-size: 1.1rem;
  }

  .paragraph-image {
    max-width: 400px;
    order: 2;
  }

  .historia-paragraph {
    gap: 10px;
    flex-direction: column-reverse;
    
  }
}

@media (max-width: 768px) {
  .historia-title {
    font-size: 2rem;
  }

  .historia-text {
    font-size: 1rem;
  }

  .paragraph-image {
    max-width: 300px;
    order: 2; /* Asegura que la imagen vaya después del texto */
  }

  .historia-paragraph {
    flex-direction: column-reverse; /* Cambia el orden de los elementos dentro del contenedor */
  }
}

@media (max-width: 480px) {
  .historia-title {
    font-size: 1.5rem;
  }

  .historia-text {
    font-size: 0.9rem;
  }

  .paragraph-image {
    min-width: 350px;
    order: 2; /* Asegura que la imagen vaya después del texto */
  }

  .historia-paragraph {
    flex-direction: column-reverse; /* Cambia el orden de los elementos dentro del contenedor */
  }
}
