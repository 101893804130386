body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F3F3F3; /* Color de fondo claro */
  
  /* Fondo en cuadrícula */
  --color: #E1E1E1;
  background-image: 
    linear-gradient(0deg, transparent 24%, var(--color) 25%, var(--color) 26%, transparent 27%, transparent 74%, var(--color) 75%, var(--color) 76%, transparent 77%, transparent),
    linear-gradient(90deg, transparent 24%, var(--color) 25%, var(--color) 26%, transparent 27%, transparent 74%, var(--color) 75%, var(--color) 76%, transparent 77%, transparent);
  background-size: 55px 55px;
  position: relative; /* Importante para el fondo absoluto del logo */
}

body::before {
  /* Fondo con el logo */
  content: "";
  background-image: url("./assets/logoBeltzak.webp");
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.05; /* Transparencia del logo */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: -1; /* Mantiene el logo detrás del contenido */
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
